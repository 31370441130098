<template>
  <a-spin class="loading" v-if="dataValue.loading" tip="数据正在加载中..."></a-spin>
  <div v-if="!dataValue.loading" class="searchDiv">
    <a-input placeholder="公司名" v-model:value="dataValue.list.input.companyNameKey"/>
    <a-input placeholder="手机号" v-model:value="dataValue.list.input.mobile"/>
    <a-select class="sel" v-model:value="dataValue.list.input.SourceTypeId" @change="allEvent.listHttp()">
      <a-select-option :value="0">全部来源</a-select-option>
      <a-select-option :value="1">闲芯</a-select-option>
      <a-select-option :value="2">易IC</a-select-option>
      <a-select-option :value="3">EC</a-select-option>
    </a-select>
    <a-select class="sel" v-model:value="dataValue.list.input.crmTypeId" @change="allEvent.listHttp()">
      <a-select-option :value="0">公司类型</a-select-option>
      <a-select-option :value="1">工厂库存</a-select-option>
      <a-select-option :value="3">工厂销售</a-select-option>
      <a-select-option :value="2">贸易商</a-select-option>
    </a-select>

    <a-select v-if="dataValue.list.input.crmTypeId === 2" class="sel" v-model:value="dataValue.list.input.crmType2Id" @change="allEvent.listHttp()">
      <a-select-option :value="0">子类型</a-select-option>
      <a-select-option :value="1">外资</a-select-option>
      <a-select-option :value="2">现货</a-select-option>
      <a-select-option :value="3">渠道</a-select-option>
      <a-select-option :value="4">统货</a-select-option>
    </a-select>

    <a-select class="sel" style="width: 70px;" v-model:value="dataValue.list.input.runStatus"
              @change="allEvent.listHttp()">
      <a-select-option :value="0">状态</a-select-option>
      <a-select-option :value="1">正常</a-select-option>
      <a-select-option :value="2">锁定</a-select-option>
    </a-select>
    <a-button type="primary" @click="allEvent.listHttp()">检索</a-button>
  </div>
  <div v-if="!dataValue.loading" class="listTable">
    <table class="ahuiTable">
      <tr>
        <th>编号</th>
        <th>来源</th>
        <th>公司类型</th>
        <th>子类型</th>
        <th>公司名</th>
        <th>地址</th>
        <th>手机号</th>
        <th>姓名</th>
        <th>电话</th>
        <th>状态</th>
        <th>员工数</th>
        <th>EC</th>
        <th>易IC</th>
        <th>创建时间</th>
        <th>设置</th>
      </tr>
      <tr v-for="aRow in dataValue.list.output.list">
        <td class="min">{{ aRow['crmCompanyId'] }}</td>
        <td class="min">{{ aRow['sourceName'] }}</td>
        <td class="min">{{ aRow['crmTypeName'] }}</td>
        <td class="min">{{ aRow['crmType2Name'] }}</td>
        <td>{{ aRow['companyName'] }}</td>
        <td>{{ aRow['address'] }}</td>
        <td class="min">{{ aRow['beginMobile'] }}</td>
        <td class="min">{{ aRow['beginUserName'] }}</td>
        <td class="min">{{ aRow['tel'] }}</td>
        <td class="min">{{ aRow['runStatusName'] }}</td>
        <td class="min">{{ aRow['userCount'] }}</td>
        <td class="min">{{ aRow['ecId'] === 0 ? "-" : aRow['ecId'] }}</td>
        <td class="min">{{ aRow['eicId'] === 0 ? "-" : aRow['eicId'] }}</td>
        <td class="min">{{ aRow['createTime'] }}</td>
        <td class="min"><a @click="allEvent.set.set(aRow)">设置</a></td>
      </tr>
      <tr>
        <td colspan="19" class="pages">
          <APages :number_of_page="dataValue.list.input.numberOfPage"
                  :this_page="dataValue.list.input.page" :all_page="dataValue.list.output.allPage"
                  @toPage="allEvent.toPage" :all_count="dataValue.list.output.allCount"
          />
        </td>
      </tr>
    </table>
  </div>

  <a-drawer
      title="设置用户的基本信息"
      width="380"
      :visible="dataValue.set.visible"
      :maskClosable="true"
      :destroyOnClose="true"
      placement="right"
      @close="allEvent.set.goClose"
  >
    <listSet :row_obj="dataValue.set.rowObj" @goClose="allEvent.set.goClose()"/>
  </a-drawer>

</template>

<script>


import {reactive} from "vue";
import httpRequest from "@/utils/axios";
import {message} from "ant-design-vue";
import APages from "@/tools/pages"
import listSet from "@/view/company/list_set";


export default {
  name : "list",
  emits : [ 'setMenuKey' ],
  components : { APages, listSet },
  setup(props, { emit }) {
    emit('setMenuKey', 'top5.1.1');

    let dataValue = reactive({
      loading : false,
      set : {
        visible : false,
        rowObj : null
      },
      list : {
        input : {
          "page" : 1,
          "numberOfPage" : 20,
          "beginDay" : "",
          "endDay" : "",
          "companyNameKey" : "",
          "crmTypeId" : 0,
          "crmType2Id" : 0,
          "mobile" : "",
          "runStatus" : 0,
          "SourceTypeId" : 1 // 0:全部 1:闲芯 2:易IC 3:EC
        },
        output : {
          allCount : 0,
          allPage : 0,
          list : []
        }

      }
    });

    let allEvent = {

      set :{

        set : (row)=>{
          dataValue.set.rowObj = row;
          dataValue.set.visible = true;
        },

        goClose :()=>{
          dataValue.set.visible = false;
        }
      },

      toPage : (pageId) => {
        dataValue.list.input.page = pageId;
        allEvent.listHttp();
      },

      listHttp : () => {

        dataValue.loading = true;

        httpRequest.post('/admin/crm/list', dataValue.list.input).then((res) => {

          if (res.code !== 200) {
            message.error(res.message);
          } else {
            dataValue.list.output.allCount = res.data['allCount'];
            dataValue.list.output.allPage = res.data['allPage'];
            dataValue.list.output.list = res.data['list'];
          }

          dataValue.loading = false;

        })
      },

      init : () => {
        allEvent.listHttp();
      }
    }

    allEvent.init();

    return {
      dataValue, allEvent
    }
  }
}
</script>

<style scoped>
.loading { width: 100%; margin-top: 50px; }
.searchDiv input { width: 200px; }
.searchDiv .sel { width: 120px; }
.searchDiv > * { margin: 10px 0 0 10px; }
.listTable { padding: 10px; }
</style>