<template>

  <a-spin class="loading" v-if="dataValue.loading" :tip="dataValue.loadingText"></a-spin>
  <div v-if="!dataValue.loading">

    <a-input placeholder="公司名称" v-model:value="dataValue.data['companyName']"/>

    <div class="title">公司类型：</div>
    <a-radio-group class="list" name="radioGroup" v-model:value="dataValue.data['crmTypeId']">
      <a-radio :value="1">工厂库存</a-radio>
      <a-radio :value="3">工厂销售</a-radio>
      <a-radio :value="2">贸易商</a-radio>
    </a-radio-group>

    <div class="title" v-if="dataValue.data['crmTypeId'] === 2">子类型：</div>
    <a-radio-group class="list" v-if="dataValue.data['crmTypeId'] === 2" name="radioGroup"
                   v-model:value="dataValue.data['crmType2Id']">
      <a-radio :value="1">外资</a-radio>
      <a-radio :value="2">现货</a-radio>
      <a-radio :value="3">渠道</a-radio>
      <a-radio :value="4">统货</a-radio>
    </a-radio-group>

    <div class="title">状态：</div>
    <a-radio-group class="list" name="radioGroup" v-model:value="dataValue.data['runStatus']">
      <a-radio :value="1">正常</a-radio>
      <a-radio :value="2">锁定</a-radio>
    </a-radio-group>

    <div class="subDiv">
      <a-button type="primary" @click="allEvent.saveInfo">保存数据</a-button>
    </div>

  </div>


</template>

<script>
import {reactive} from "vue";
import httpRequest from "@/utils/axios";
import {message} from "ant-design-vue";

export default {
  name : "list_set",
  props : [ 'row_obj' ],
  emits : [ "goClose" ],
  setup(props, { emit }) {

    let dataValue = reactive({
      loading : false,
      loadingText : "",
      rowObj : props.row_obj,
      data : {},
    })

    let allEvent = {

      getInfo : (haveClose) => {

        if (!haveClose) {
          dataValue.loading = true;
          dataValue.loadingText = "数据正在加载中";
        }

        httpRequest.post('/admin/crm/get', {
          "crmCompanyId" : dataValue.rowObj['crmCompanyId']
        }).then((res) => {
          if (res.code !== 200) {
            message.error(res.message);
          } else {
            dataValue.data = res.data;
          }

          if (!haveClose) {
            dataValue.loading = false;
          } else {
            dataValue.rowObj['companyName'] = dataValue.data['companyName'];
            dataValue.rowObj['crmTypeName'] = dataValue.data['crmTypeName'];
            dataValue.rowObj['crmType2Name'] = dataValue.data['crmType2Name'];
            dataValue.rowObj['runStatusName'] = dataValue.data['runStatusName'];

            emit('goClose')
          }

        });

      },

      saveInfo : () => {

        if (dataValue.data['companyName'].trim() === "") {
          message.error("公司名不能为空");
        } else {

          dataValue.loading = true;
          dataValue.loadingText = "数据正在保存中";

          httpRequest.post('/admin/crm/set', dataValue.data).then((res) => {
            if (res.code !== 200) {
              message.error(res.message);
            }

            allEvent.getInfo(true);

          });
        }


      },

      init : () => {
        allEvent.getInfo(false);
      }

    }

    allEvent.init();


    return { dataValue, allEvent }
  }
}
</script>

<style scoped>
.loading { text-align: center; margin-top: 50px; width: 100%; }
.title { margin: 20px 0 15px 0; font-weight: bold; }
.list { margin: 2px 0 2px 20px; }
.subDiv { clear: both; width: 100%; text-align: center; margin-top: 50px; }
</style>